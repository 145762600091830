<template>
  <layout-admin-dashboard-view :show-title-back-link="false">
    <template #title>
      {{ translateTitleCase("admin.dashboard.dashboardTitle") }}
    </template>

    <template #mainContent>
      <card>
        <div class="flex flex-stretch gap-l flex-wrap">
          <block
            v-for="link of links"
            :key="link"
            :link="link"
            style="flex: 30%; min-width: 250px">
            <router-link
              v-slot="{ navigate, href, route }"
              :to="link.to"
              custom>
              <panel
                theme="inverse"
                :icon="link.icon"
                :title="link.label"
                style="cursor: pointer"
                @click="navigate">
                <p>{{ translateSafe(link.description) }}</p>
              </panel>
            </router-link>
          </block>
        </div>
      </card>
    </template>
  </layout-admin-dashboard-view>
</template>

<script>
import { useMeta } from "vue-meta";
import { useI18n } from "vue-i18n/index";

export default {
  setup() {
    let { t } = useI18n();
    let { meta } = useMeta({
      title: t("admin.dashboard.title"),
    });
    //meta.sitename.content = 'Saffron Admin';

    return { meta };
  },
  data: function () {
    return {
      links: [
        {
          to: { name: "admin-vendor" },
          icon: "cart",
          shortLabel: "admin.vendors.shortLabel",
          label: "admin.vendors.label",
          description: "admin.vendors.description",
        },
        {
          to: { name: "entity-dashboard" },
          icon: "copy",
          shortLabel: "admin.entity.titleShort",
          label: "admin.entity.title",
          description: "admin.entity.description",
        },

        {
          to: { name: "admin-user" },
          icon: "user",
          shortLabel: "admin.user.titleShort",
          label: "admin.user.title",
          description: "admin.user.description",
        },
        {
          to: { name: "admin-language" },
          icon: "chat",
          shortLabel: "admin.language.titleShort",
          label: "admin.language.title",
          description: "admin.language.description",
        },
      ],
    };
  },
  created() {
    //   console.log(this.meta);
  },
};
</script>

<style scoped lang="scss">
.admin-item-link {
  cursor: pointer;

  .uk-card-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .uk-icon {
      position: relative;
      margin-right: 4px;
      // top: 0px;
    }
  }
}
</style>
